import React, { Component } from 'react';


const TrustBox = ({ trustBoxRef, tp, tpHeight }) => (
 
  <>
      <div
      ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
      className="trustpilot-widget" // Renamed this to className.
      // [ long list of data attributes...]
      data-template-id={tp}
      data-businessunit-id="59d4a3700000ff0005acc2ea"
      data-locale="en-GB"
      data-style-width="100%"
      data-style-height={tpHeight}
      // style={{ paddingTop: '20px' }}
    >
  <a
    href="https://uk.trustpilot.com/review/boringmoney.co.uk"
    target="_blank"
    rel="noopener noreferrer"
  >
    Trustpilot
  </a>
    </div>
  </>
);
class TrustBoxContainer extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
    this.tpMarkUp = props.code[0].children[0].text;
   
  }

  componentDidMount() {

      // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
      // If it's not, it means the script pasted into <head /> isn't loaded just yet.
      // When it is, it will automatically load the TrustBox.
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
      }
  }
  render() {

    const myArr = this.tpMarkUp.split(" ");
    var dataTemplateId = "";
    var dataStyleHeight = "";

    for (var i = 0; i < myArr.length; i++) {
      if (myArr[i].includes("data-template-id")) {
        dataTemplateId = myArr[i].replace('data-template-id="', '').replace('"', '');
      }
      if (myArr[i].includes("data-style-height")) {
        dataStyleHeight = myArr[i].replace('data-style-height="', '').replace('"', '');
      }
    }

    return <TrustBox trustBoxRef={this.trustBoxRef} tp={dataTemplateId} tpHeight={dataStyleHeight} />;
  }
}
export default TrustBoxContainer;
